import { SxProps } from '@mui/system';

export enum MenuListVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export interface MenuListProps {
  variant?: 'primary' | 'secondary' | 'tertiary';
  children: React.ReactNode;
  sx?: SxProps;
  isActive?: boolean;
}
