import { Locales } from 'src/lib/constants';
import { NAVIGATION_EVENTS } from 'src/lib/trackingEvents';

export const footerData = {
  copyrightName: 'Plaid Inc.',
  eeaDisclaimer: `Plaid Financial Ltd. is an authorised payment institution regulated by the Financial Conduct Authority under the Payment Services Regulations 2017 (Firm Registration Number: 804718) for the provision of payment services. Plaid, B.V. is an authorised payment institution regulated by the Dutch Central Bank under the Dutch Financial Supervision Act for the provision of payment services (account information services). Plaid, B.V. is included in the public register of the Dutch Central Bank (License number: R179714) and registered at the Dutch Chamber of Commerce (CoC number: 74716603).`,
  hideCTAWhen: [
    '404',
    '500',
    'account-funding',
    'ach-start',
    'ACH-start',
    'assets',
    'auth',
    'balance',
    'banking',
    'business-brokerage-services',
    'business-finances',
    'careers',
    'consumer-payments',
    'contact-thanks',
    'contact',
    'customer-stories',
    'global',
    'how-it-works-for-consumers',
    'how-we-handle-data',
    'identity',
    'income',
    'integration',
    'investments',
    'job',
    'lending',
    'liabilities',
    'openings',
    'partners',
    'payment-initiation',
    'payments',
    'personal-finances',
    'plaid-exchange',
    'signup',
    'transactions',
    'use-cases',
    'wealth',
    'what-is-plaid',
    'why-is-plaid-involved',
    'trouble-connecting',
    '/', // The "/" represents homepage
  ],
  sections: {
    products: {
      header: 'Products',
      items: [
        {
          name: 'Payment Initiation',
          url: '/en-eu/products/payment-initiation',
        },
        {
          name: 'Transactions',
          url: '/en-eu/products/transactions',
        },
        {
          name: 'Balance',
          url: '/en-eu/products/balance',
        },
        {
          name: 'Assets',
          url: '/en-eu/products/assets',
        },
        {
          name: 'Identity',
          url: '/en-eu/products/identity',
        },
        {
          name: 'Auth',
          url: '/en-eu/products/auth',
        },
        {
          name: 'Plaid Link',
          url: '/en-eu/plaid-link',
        },
        {
          name: 'Global coverage',
          url: '/global',
        },
        {
          name: 'Pricing',
          url: '/en-eu/pricing',
          trackingId: NAVIGATION_EVENTS.FOOTER.USER_CLICKED_FOOTER_NAV_PRICING,
        },
      ],
    },
    useCases: {
      header: 'Use cases',
      items: [
        {
          name: 'Personal finances',
          url: '/en-eu/use-cases/personal-finances',
        },
        {
          name: 'Business finances',
          url: '/use-cases/business-finances',
        },
        {
          name: 'Payments',
          url: '/en-gb/use-cases/payments',
        },
        {
          name: 'Lending',
          url: '/en-eu/use-cases/lending',
        },
        {
          name: 'Banking & wealth',
          url: '/en-eu/use-cases/banking-and-wealth',
        },
        {
          name: 'iGaming',
          url: '/en-eu/use-cases/igaming',
        },
        {
          name: 'Customer stories',
          url: '/customer-stories',
        },
      ],
    },
    docs: {
      header: 'For developers',
      items: [
        {
          name: 'Quickstart',
          url: '/docs/quickstart',
          locale: Locales.EN_US, // forces link to en-us
        },
        {
          name: 'API documentation',
          url: '/docs',
          locale: Locales.EN_US, // forces link to en-us
        },
        {
          name: 'Libraries',
          url: '/docs/api/libraries',
          locale: Locales.EN_US, // forces link to en-us
        },
      ],
    },
    aboutUs: {
      header: 'About us',
      items: [
        {
          name: 'Company',
          url: '/company',
        },
        {
          name: 'Careers',
          url: '/careers',
          locale: Locales.EN_US, // forces link to en-us
        },
        {
          name: 'Blog',
          url: '/blog',
          locale: Locales.EN_US, // forces link to en-us
        },
        {
          name: 'Contact',
          url: '//dashboard.plaid.com/contact',
        },
        {
          name: 'Partners',
          url: '/partners',
        },
        {
          name: 'Press',
          url: '/press',
        },
        {
          name: 'Resources',
          url: '/resources',
        },
        {
          name: 'Fin',
          url: '//fin.plaid.com',
        },
        {
          name: 'Safety',
          url: '/safety',
        },
        {
          name: 'How we handle data',
          url: '/how-we-handle-data',
        },
        {
          name: 'Legal & Privacy',
          url: '/legal',
        },
      ],
    },
    forConsumers: {
      header: 'For consumers',
      items: [
        {
          name: 'Trouble connecting?',
          url: '/trouble-connecting',
        },
        {
          name: 'Manage connections',
          url: '//my.plaid.com',
        },
        {
          name: 'FAQs',
          url: '//my.plaid.com/help',
        },
      ],
    },
  },
};
