import { SxProps } from '@mui/system';

export enum MenuHeadingVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}
export interface MenuHeadingProps {
  title: string;
  sx?: SxProps;
  variant?: 'primary' | 'secondary' | 'tertiary';
}
