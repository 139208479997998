import React from 'react';
import styled from '@mui/system/styled';

// components
import { Button, Link } from 'src/components/Inputs';
import MobileNavbarMenuItem from './MobileNavbarMenuItem';
import PlaidLogo from 'src/components-v2/SVGs/PlaidLogo';

// types
import { MobileNavbarProps } from './types';

const Nav = styled('nav')(({}) => {
  return {};
});

const UnorderedList = styled('ul')(({}) => {
  return {};
});

const bodyNoScroll = (e) => {
  // when mobile nav is tapped open, make sure user can't scroll the page.
  // some browsers need the properties set on the <html> elements *cough* firefox *cough*
  // while the others need it only set on the body.
  if (e.target.checked) {
    document.querySelector('html').style.overflowY = 'hidden';
    document.querySelector('html').style.height = '100%';
    document.body.style.overflowY = 'hidden';
    document.body.style.height = '100%';
  } else {
    document.querySelector('html').style.overflowY = '';
    document.querySelector('html').style.height = '';
    document.body.style.overflowY = '';
    document.body.style.height = '';
  }
};

const MobileNavbar: React.FC<MobileNavbarProps> = ({
  logoAlt,
  menuItems,
  login,
  getStarted,
  getStartedTrackingId = '',
}) => {
  return (
    <Nav className='MobileNavbar'>
      <Link href='/'>
        <a
          className='MobileNavbar__logo-container'
          aria-label='Go to https://plaid.com/'
        >
          <PlaidLogo className='MobileNavbar__logo' />
        </a>
      </Link>
      <input
        type='checkbox'
        id='mobile-navbar-menu-toggle'
        data-id='mobile-navbar-menu-toggle'
        className='MobileNavbar__checkbox'
        onClick={bodyNoScroll}
      />
      <label
        htmlFor='mobile-navbar-menu-toggle'
        className='MobileNavbar__menu-toggle'
      />
      <div className='MobileNavbar__hamburger-icon'>
        <span className='MobileNavbar__hamburger-icon-line'></span>
        <span className='MobileNavbar__hamburger-icon-line'></span>
        <span className='MobileNavbar__hamburger-icon-line'></span>
      </div>
      <UnorderedList className='MobileNavbar__menu'>
        {menuItems.map((item, i) => {
          return (
            <MobileNavbarMenuItem
              {...item}
              count={i + 1}
              key={`mobile-nav-menu-${i}`}
            />
          );
        })}
        <div className='MobileNavbar__mobile-action-button-container'>
          <Button
            href={login.url}
            className='MobileNavbar__action button'
            secondary
            centered
          >
            {login.name}
          </Button>
          <Button
            href={getStarted.url}
            className='MobileNavbar__action button signup-attach-utm'
            centered
            trackingId={getStartedTrackingId}
          >
            {getStarted.name}
          </Button>
        </div>
      </UnorderedList>
    </Nav>
  );
};

export default MobileNavbar;
