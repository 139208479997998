import React from 'react';
import { useRouter } from 'next/router';
import { SxProps } from '@mui/system';

// components
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';

// contexts
import { usePageLocale } from 'src/contexts';

// helpers
import { mutateHardCodedLinks } from 'src/lib/utils';
import { Locales } from 'src/lib/constants';

import * as enUsMainNav from 'src/store/navigation/main-nav';
import * as enEuMainNav from 'src/store/en-eu/navigation/main-nav';
import * as enGbMainNav from 'src/store/en-gb/navigation/main-nav';

const mainNavDataPerLocale = {
  [Locales.EN_US]: enUsMainNav,
  [Locales.EN_EU]: enEuMainNav,
  [Locales.EN_GB]: enGbMainNav,
};

interface MainNavProps {
  skipLinkAnchor?: string;
  getStartedTrackingId?: string;
  navbarStyles?: {
    sx: SxProps;
  };
}

const MainNav: React.FC<MainNavProps> = ({
  skipLinkAnchor = '#main-content',
  getStartedTrackingId = '',
  navbarStyles = {},
}) => {
  const { asPath } = useRouter();
  const onBlogPage = asPath.includes('blog');
  const locale = usePageLocale();
  const { mainNavData } = mutateHardCodedLinks({
    data: mainNavDataPerLocale[locale] || mainNavDataPerLocale[Locales.EN_US],
    locale,
  });
  return (
    <>
      <DesktopNavbar
        skipLinkAnchor={skipLinkAnchor}
        onBlogPage={onBlogPage}
        getStartedTrackingId={getStartedTrackingId}
        locale={locale}
        {...navbarStyles}
        {...mainNavData}
      />
      <MobileNavbar
        getStartedTrackingId={getStartedTrackingId}
        locale={locale}
        {...mainNavData}
        {...navbarStyles}
      />
    </>
  );
};

export default MainNav;
